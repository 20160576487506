







































































































import {Component, Mixins, Prop, Watch, Vue} from "vue-property-decorator";
import {mapGetters, mapMutations} from "vuex";
import { appealForm } from "@/assets/scripts/form-fields/main";
import XForm from "@/components/SimpleForm.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import {validationMixin} from "vuelidate";
import {AppealsByContract, AppealThemes, AppealThemeTypes } from "@/models/appeals";
import {OrganizationAgent } from "@/models/organization";
import {AccountingPoint } from "@/models/accounting-point";
import Page from "@/components/for-page-grid/Page.vue";
import XNotice from "@/components/hoc/SimpleNotice.vue";

const { values, attrs, validations, errors } = appealForm;

@Component({
  components: {
    Page,
    XForm,
    XNotice,
  },
  computed: {
    ...errors,
    ...mapGetters({
      email: "user/email",
      orgContactAgent: "organization/agentList",
      nameOrg: "organization/nameOrg",
      contractId: "contract/id",
      orgIdOfContractId: "contract/orgIdOfContractId",
      themeName: "appeals/themeName",
      themeId: "appeals/themeId",
      themeTypes: "appeals/themeTypes",
      themeTypesId: "appeals/themeTypesId",
      accountingPoints: "accountingPoints/allPoints",

    }),
  },
  methods: {
    ...mapMutations("organization", {
      setAgents: "setAgents",
    }),
  },
  mixins: [validationMixin],
  validations,
})
class AppealAddingForm extends Mixins(AppApiMixin, XForm) {
  [x: string]: any

  values = values
  defaultValues: {[x: string]: any} = {}
  allFieldAttrs = attrs;
  initialSelectValue = 'Выберите тему обращения'
  initialSelectValue2 = 'Выберите вопрос темы обращения'
  initialSelectValue3 = 'Выберите точку учета'
  noAPointsForSelect = 'Точки учета отсутствуют'
  //file: File | string = '';
  //file: File | [];
  file: Array<File> | [];
  useSuccessAlert = false
  successMessage = 'Обращение успешно отправлено.'
  useSubmitButtonLoading = false;
  submitError = "";

  arr_files = [];

  theme_mas: {[x: string]: number} = {}
  themeType_mas: {[x: string]: number} = {}
  apoints_mas: {[x: string]: number} = {}
  activeThemeId = 1;
  submitSuccess = '';
  themeTypeItems = [];
  firstMessage = "";
  temp_mas = [];
  file_count = 0;

  @Prop({ default: false }) readonly show!: boolean;

  mounted() {
    this.values = {...this.values, email: this.userEmail, appealThemes: this.initialSelectValue};
    this.defaultValues = {...this.values, email: this.userEmail, appealThemes: this.initialSelectValue};

    this.getAllAppealThemes(this.contractId);
    this.getOrgAgents(this.orgIdOfContractId,(agents: OrganizationAgent[]) => {
      this.setAgents(agents);
      const author_agents = this.orgContactAgent.map((name: OrganizationAgent) => name.фио);
      var agents_str = '';
      if (author_agents.length > 0) {
        author_agents.forEach((item,i) => {
          agents_str += author_agents[i] + ', ';
        });
        agents_str = agents_str.replace(/[\s,]+$/g,'').trim();
      } else {
        agents_str = this.nameOrg;
      }
      this.temp_mas.push(agents_str);
    });

    const d = document.querySelector('.themeType-select') as HTMLElement;
    const dprev = document.querySelector('.themeType-select').previousElementSibling as HTMLElement;
    d.style.display = 'none';
    dprev.style.display = 'none';

  }

  public get accountingPointsForSelect() {
    const aPoints = this.accountingPoints ? this.accountingPoints.map((name: AccountingPoint) => name.названиету) : [];
    const aPoints_ids = this.accountingPoints ? this.accountingPoints.map((name: AccountingPoint) => name.лицевой) : [];

    aPoints.forEach((item, index) => {
      this.apoints_mas[item] = aPoints_ids[index];
    });

    if (aPoints.length > 0) {
      return [...aPoints];
    } else {
      return [this.noAPointsForSelect];
    }
  }

  public get themesForSelect() {
    const themes = this.themeName ? this.themeName.map((name: AppealThemes) => name.название) : [];
    const theme_mas_ids = this.themeName ? this.themeName.map((name: AppealThemes) => name.$номерЗаписи) : [];

    themes.forEach((item, index) => {
      this.theme_mas[item] = theme_mas_ids[index];
    });

    return [...themes]
  }
  get themeTypesForSelect() {
    const themeTypes = this.themeTypes ? this.themeTypes.map((name: AppealThemeTypes) => name.название) : [];
    const themeTypes_mas_ids = this.themeTypes ? this.themeTypes.map((name: AppealThemeTypes) => name.$номерЗаписи) : [];

    themeTypes.forEach((item:number, index:number) => {
      this.themeType_mas[item] = themeTypes_mas_ids[index];
    });

    return [...themeTypes];
  }
  onSelectTheme() {
    for (let key in this.theme_mas) {
      if (key == this.values.typeAppeal) {
        this.activeThemeId = this.theme_mas[key];
      }
    }

    const theme_types = this.getAllAppealThemeTypes(this.activeThemeId, this.contractId);
    theme_types.then((result) => {
      this.themeTypeItems = result;
      if (this.themeTypeItems.length == 0) {
        const d = document.querySelector('.themeType-select') as HTMLElement;
        const dprev = document.querySelector('.themeType-select').previousElementSibling as HTMLElement;
        d.style.display = 'none';
        dprev.style.display = 'none';
      } else {
        const d = document.querySelector('.themeType-select') as HTMLElement;
        const dprev = document.querySelector('.themeType-select').previousElementSibling as HTMLElement;
        d.style.display = 'unset';
        dprev.style.display = 'unset';
      }

    });
  }

  get userEmail() {
    return this.email
  }

  public log(...data: any) {
    data.forEach((m:any) => console.log(m))
  }

  //onFileChanged(files: File) {
  onFileChanged(files: Array<File>) {
    this.file = files;
  }

  async submitForm() {

    this.useSuccessAlert = false
    this.useSubmitButtonLoading = true
    const formData = new FormData

    for (let key in this.values) {
      if (key == 'typeAppeal') {
        this.values[key] = this.theme_mas[this.values[key]];
        formData.append(key, this.values[key]);
      }
      if (key == 'typeAppeal2') {
        if (this.values["typeAppeal2"] != '') {
          this.values[key] = this.themeType_mas[this.values[key]];
        }
        formData.append(key, this.values[key]);
      }
      if (key == 'accountingPointNumber') {
        if (this.apoints_mas) {
          this.values[key] = this.apoints_mas[this.values[key]];
        } else {
          this.values[key] = '';
        }
        formData.append(key, this.values[key]);
      }


      if (key == 'file') {
        //this.arr_files.push(this.file);
        //this.values[key] = this.arr_files;
        this.values[key] = this.file;
        //console.log('-------');
        //console.log(this.values[key]);
        //console.log('-------');


        for (let fille of this.file) {
          //let i = 0;
          formData.append(key+this.file_count,fille);
          this.file_count++;
        }
      }
      if (key == 'message') {
        this.firstMessage = this.values["message"];
        formData.append(key, this.values[key]);
      }
    }

    formData.append('contractId', this.contractId);
    formData.append('author', this.temp_mas[0]);
    formData.append('file_count', String(this.file_count));

    console.log(formData);

    try {
      const data = await this.sendAppeal(formData)

      console.log(data);
      if(data && data.result && (data.appealId) != 0) {
        /*const formDataMessage = new FormData;
        formDataMessage.append('contractId', this.contractId);
        formDataMessage.append('appealId', data.appealId);
        formDataMessage.append('message', this.firstMessage);
        formDataMessage.append('firstMessage', '0');
        formDataMessage.append('author', this.temp_mas[0]);*/

        //const dataMessage = await this.sendAppealMessage(formDataMessage);

        this.submitSuccess = 'Обращение успешно отправлено';
        this.values = {...this.defaultValues}
        this.useSuccessAlert = true
        //@ts-ignore
        //this.$refs.fileInput.value = ''
        //@ts-ignore
        //this.$refs.fileInput.reset()

        for (let key in this.values) {
          //@ts-ignore
          this.$v.values[key] && this.$v.values[key].$reset()
        }
        this.$emit('updateTable');
        this.$emit("update:show", false);
      }

    } catch (e) {
      console.log('error', e);
      //this.useSuccessAlert = false;
      this.submitError = e.data;
      console.log(this.submitError);
    }
    this.submitSuccess = '';
    this.useSubmitButtonLoading = false
  }
}

export default AppealAddingForm
