var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-data-table',{staticClass:"data-table_type_appeals",attrs:{"headers":_vm.preparedHeaders,"items":_vm.preparedAppeals,"use-item-slot":true},on:{"checkbox":function () {
        if (!_vm.selectedAll || _vm.selectedRows.some(function (state) { return !state; })) {
          _vm.selectedAll = true;
        } else {
          _vm.selectedAll = false;
        }
        _vm.selectedRows = Array(_vm.appealsLength).fill(_vm.selectedAll);
      }},scopedSlots:_vm._u([{key:"afterCaption",fn:function(){return [_c('div',{staticClass:"data-table__head-content"},[_c('x-dropdown-with-date-picker',{attrs:{"dates":_vm.dates},on:{"update:dates":function($event){_vm.dates=$event}}})],1)]},proxy:true},{key:"item",fn:function(ref){
      var ref_item = ref.item;
      var item = ref_item.item;
      var index = ref_item.index;
return [_c('tr',{class:[
          item['unread'] > 0 && (item['flag'] % 4 >= 2) ? 'isUnreadStrong' : '',
          'data-table__text',
          'data-table__text_cursor_default' ],on:{"click":function($event){return _vm.setInfoAppealTo(item['id_main'])}}},_vm._l((item),function(value,header){return _c('td',{key:("" + header),class:_vm.tdClassesByHeaders[header]},[(header.endsWith('datta'))?_c('div',{staticClass:"d-flex flex-column justify-start"},[_c('span',{staticClass:"secondary--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")])]):(header.endsWith('nomer'))?[_vm._v(" № "+_vm._s(value || "—")+" ")]:(header.endsWith('vidObr'))?_c('span',{class:[
              value &&
                [
                  'd-block',
                  'align-center',
                  'justify-center',
                  'icon',
                  'icon_size_md',
                  'rounded',
                  'text--lighten-5' ].join(' ') ]},[_vm._v(" "+_vm._s(value || "—")+" ")]):(header.startsWith('status'))?_c('span',{staticClass:"d-block text-left text"},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()],2)}),0)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }